<template>
  <div>
      <div class="card-point display-between" style="">
        <div>{{$t('energy.enedata298')}}</div>
        <div>{{ pointInfo==null || pointInfo.pointNo==null?'-':pointInfo.pointNo }}</div>
      </div>
      <div class="card-point display-between">
        <div>{{$t('energy.enedata164')}}</div>
        <div>{{ pointInfo==null || pointInfo.pointName==null?'-':pointInfo.pointName}}</div>
      </div>
      <div class="card-item display-between">
        <div>{{$t('energy.enedata002')}}</div>
        <div>{{ pointInfo==null || pointInfo.err==null?'-':pointInfo.err}}</div>
      </div>
      <div class="card-item display-between">
        <div>{{$t('energy.enedata297')}}</div>
        <div>{{ pointInfo==null || pointInfo.msg==null?'-':pointInfo.msg}}</div>
      </div>
      <div class="card-item display-between">
        <div>{{$t('energy.enedata165')}}</div>
        <div>{{ pointInfo==null || pointInfo.unit==null?'-':pointInfo.unit}}</div>
      </div>
    </div>
    
</template>
<script>
export default {
  name: 'EneOwnTenCardDetail',
  props: {
    pointInfo: {
      type: Object,
      default: () => ({}),
    },
  }, 
  methods: {
   
  },
   components: {
  },
};
</script>

<style scoped>
    .card-point{
      padding: 5px;
      margin: 5px;
      font-size: 14px;
      border-top: 1px solid #f5f5f5;
      width:100%;
    }
    .card-item{
      padding: 5px;
      margin:  5px;
      border-top: 1px solid #f5f5f5;
    }
    .display-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
   
</style>