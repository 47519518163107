<template>
    <div class="display-between">
        <span>{{title}}</span>
        <a-select default-value="" style="width: 200px" @change="handleChange">
            <a-select-option
              v-for="(itme, index) in eneKinds"
              :key="index"
              :value="itme.no"
            >
              {{ itme.text }}
            </a-select-option>
        </a-select>
    </div>
</template>
<script>
export default {
  name: 'EneHeader',
  props: {
    title: {
      type: String,
      default: "能源类型",
    },
    eneKinds: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
  }, 
  methods: {
    handleChange(e,no,text) {
      console.log("handleChange get:", e);
     if(this.fatherChangeMethod)
      this.fatherChangeMethod(e,no,text);
    },
  },
   components: {
  },
};
</script>

<style scoped>
    
    .display-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .display-between  span {
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
</style>