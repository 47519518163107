<template>
    <div class="display-between">
        <!-- <span>{{title}}</span> -->
        <a-radio-group v-model="menu"  button-style="solid" @change="switchPage">
           <a-radio-button  v-for="(item,index) in options" :key="index" :value="item.value"  >{{item.label}}</a-radio-button>
        </a-radio-group>
    </div>
</template>
<script>

const paths = [
  { id: '1', value: '/homepage/energy/setup/eneMeasurePoint' },
  { id: '2', value: '/homepage/energy/setup/eneOwnTenObject' },
  { id: '3', value: '/homepage/energy/setup/eneMeasureObject' },
  { id: '4', value: '/homepage/energy/setup/eneVirtialPoint' },
];

export default {
name:'EnePointMenu',
props:{
    title:{
        type:String,
        default:'设置内容',
    },
    vale:{
        type:String,
        default:'1',
    }
},
  data() {
    // this.pathMap = paths.map(item => ({ ...item }));
    // console.log(this.pathMap);
    return {
      options: [
          { label: this.$t('energy.enedata060')       , value: '1' },
          { label: this.$t('energy.enedata294')       , value: '2' },//'本司/租户区分'
          { label: this.$t('energy.enedata295')       , value: '3' },//'计量对象区分'
          { label: this.$t('energy.enedata296')       , value: '4' },//'虚拟点'
        ],
      paths,
      menu:this.vale,
    };
  },
  methods: {
    switchPage(e){
        console.log(e);
        console.log("page",e.target.value);
        let page = e.target.value;
        this.$parent.page = page;
        console.log("page",page);
    },
  },
};
</script>

<style scoped>
    .display-column{
        display: flex;
        flex-direction: column;
    }
    .display-between  span {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .display-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
</style>