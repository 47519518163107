<template>
  <div id="eneVirtialPointDetail" ref="eneVirtialPointDetail">
    <a-modal v-model="visible" :title="title"  :width="800" :getContainer="() => this.$refs.eneVirtialPointDetail" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
        <a-form-model ref="ruleForm" 
        :model="form" 
        :rules="rules"
        :label-col="labelCol" 
        :wrapper-col="wrapperCol"  >
            <a-form-model-item label="TagPoint" v-if="form.id>0">
                <a-input :readOnly="true" v-model="form.tagPoint" />
            </a-form-model-item>
            <a-form-model-item ref="name" prop="name">
                 <span slot="label">
                                {{$t('energy.enedata164')}}&nbsp;
                    <a-tooltip :title='disallowed'>
                    <a-icon type="question-circle-o" />
                    </a-tooltip>
                </span>
                <a-input 
                    v-model="form.name"
                    @blur="
                    () => {
                        $refs.name.onFieldBlur();
                    }
                    "
                    :placeholder="$t('energy.enedata261')"
                />
            </a-form-model-item>

            <a-form-model-item :label="$t('energy.enedata199')" ref="grs" prop="grs">
                <a-cascader 
                    v-model="form.grs" 
                    :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="options" 
                    change-on-select  :placeholder="$t('energy.enedata228')" />
            </a-form-model-item>
        
            <a-form-model-item :label="$t('energy.enedata259')" ref="opt" prop="opt">
                <a-select v-model="form.opt" :placeholder="$t('energy.enedata263')" @change="handleChange">
                    <a-select-option
                        v-for="(itme, index) in optList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('energy.enedata060')" ref="kind" prop="kind">
                <a-select v-model="form.kind" :placeholder="$t('energy.enedata237')" @change="handleKindChange" :disabled="kindDis">
                    <a-select-option
                        v-for="(itme, index) in kindList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>


            <a-form-model-item :label="$t('energy.enedata203')" ref="unit" prop="unit">
                <a-select v-model="form.unit" :placeholder="$t('energy.enedata205')" :disabled="unitDis">
                    <a-select-option
                        v-for="(itme, index) in unitList"
                        :key="index"
                        :value="itme.no"
                    >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('energy.enedata207')" ref="decimalPoint" prop="decimalPoint">
                <a-select v-model="form.decimalPoint" :placeholder="$t('energy.enedata227')" :disabled="decmDis">
                    <a-select-option
                        v-for="(itme, index) in decimalList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

             <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
            
        </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
import {saveVirtialPointData} from "../../../api/energy";
export default {
  name: 'EneVirtialPointDetail',
  props: {
    title: {
      type: String,
      default: '虚拟点设置',
    },
    data: {
      type: Object,
    }
  },
  data() {
      let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata260')));
        } else {
        callback();
      }
    };
    return {
        cloading:false,
        form:{id:0,name:'',opt:'',kind:'',unit:'',decimalPoint:'',grs:[],tagPoint:'',},
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        },
        optList:[],
        kindList:[],
        unitList:[],
        options:[],
        decimalList:[],
        grs:[],
        kindMap:null,
        unitMap:null,
        kindDis: false,
        unitDis: false,
        decmDis: false,
        visible: false,
        rules: {
            name: [
                { required: true, message: this.$t('energy.enedata261'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata262'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            opt: [{ required: true, message: this.$t('energy.enedata263'), trigger: 'change' }],
            kind: [{ required: true, message: this.$t('energy.enedata237'), trigger: 'change' }],
            unit: [{ required: true, message: this.$t('energy.enedata205'), trigger: 'change' }],
            decimalPoint: [{ required: true, message: this.$t('energy.enedata227'), trigger: 'change' }],
            grs:[{
                type: 'array',
                required: true,
                message: this.$t('energy.enedata181'),//'请选择所属',
                trigger: 'change',
                 },
          ],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.detail.visible":{
      handler(val){
          if(val){              
              this.visible = this.$parent.detail.visible;
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.detail.visible =  this.visible;
          }
      },
      deep:true,
      immediate:true,
    },
    "data":{
      handler(val){
            this.resetForm();
            console.log('data',val);
            if(val.dictionary!=null){
                this.form.id = val.virtualPoint.id;
                this.form.name = val.virtualPoint.name;
                this.form.tagPoint = val.virtualPoint.tagPoint;
                this.kindMap = new Map();
                this.unitMap = new Map();
                this.optList = val.dictionary.optList;  
                for (let i = 0; i < val.dictionary.optList.length; i++) {
                    const element = val.dictionary.optList[i];
                    this.kindMap.set(element.no,element.children);
                    for (let j = 0; j < element.children.length; j++) {
                        const elemt = element.children[j];
                        this.unitMap.set(elemt.no,elemt.children);
                    }
                }
                console.log('kindMap',this.kindMap);
                console.log('unitMap',this.unitMap);
                this.form.opt = val.virtualPoint.opt+'';
                if( this.form.opt==='1'){
                    this.kindList   =   this.kindMap.get(this.form.opt);
                    this.form.kind  =   val.virtualPoint.kind+'';
                    this.unitList   =   this.unitMap.get(this.form.kind);
                    this.form.unit  =   val.virtualPoint.unit+'';
                    this.form.decimalPoint = val.virtualPoint.decimalPoint+'';
                    this.kindDis = false;
                    this.unitDis = false;
                    this.decmDis = false;
                }else{
                    this.kindList   =   [{no:'0',text:''}];
                    this.form.kind = '0';
                    this.form.unit = '￥';
                    this.form.decimalPoint = '0';
                    this.kindDis = true;
                    this.unitDis = true;
                    this.decmDis = true;
                }
                console.log('grs',this.form.grs);

                this.form.grs = new Array();
                if(val.virtualPoint.gr1!=0){
                    this.form.grs.push(val.virtualPoint.gr1+'');
                }
                if(val.virtualPoint.gr2!=0){
                    this.form.grs.push(val.virtualPoint.gr2+'');
                }
                if(val.virtualPoint.gr3!=0){
                    this.form.grs.push(val.virtualPoint.gr3+'');
                }
                this.options = val.dictionary.grs;
                this.decimalList = val.dictionary.decimalList;
                this.cloading =false;
            }

            console.log('over',this.form);
           
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
        handleChange(e){
            console.log('handleChange',e);
            if(e==='1'){
                this.kindList = this.kindMap.get(e);
                if(this.kindList!=null && this.kindList.length>0){
                    this.form.kind = this.kindList[0].no;
                    this.unitList = this.unitMap.get(this.form.kind);
                    this.form.unit = this.unitList[0].no;
                }else{
                    this.form.kind = '';
                    this.form.unit = '';
                }
                this.form.decimalPoint = '2';
                this.kindDis = false;
                this.unitDis = false;
                this.decmDis = false;
            }else{
                this.kindList   =   [{no:'0',text:''}];
                this.form.kind = '0';
                this.form.unit = '￥';
                this.form.decimalPoint = '0';
                this.kindDis = true;
                this.unitDis = true;
                this.decmDis = true;
            }
        
        },
        handleKindChange(e){
            console.log('handleKindChange',e);
            this.unitList = this.unitMap.get(e);
            if(this.unitList!=null && this.unitList.length>0){
                this.form.unit = this.unitList[0].no;
            }else{
                this.form.unit = '';
            }
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: this.$t('energy.enedata264'),//'确认要保存所设置的虚拟点信息吗？',
                        centered: true,
                        onOk: () => {
                            this.savaVirtialPoint();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.ruleForm){
                this.$refs.ruleForm.resetFields();
            }
        },
        savaVirtialPoint(){
            let gr1 = this.form.grs.length>0?this.form.grs[0]:0;
            let gr2 = this.form.grs.length>1?this.form.grs[1]:0;
            let gr3 = this.form.grs.length>2?this.form.grs[2]:0;
              
            let param = {
                 action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                id: this.form.id,
                name: this.form.name,
                gr1: gr1,
                gr2: gr2,
                gr3: gr3,
                opt: this.form.opt,
                kind: this.form.kind,
                unit: this.form.unit,
                decimalPoint: this.form.decimalPoint,
            };
            this.cloading = true;
            console.log("SavaVirtialPoint param",param);
            // setTimeout(()=>{
            //      this.cloading = false;
            // },10000);
            saveVirtialPointData(param)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '03'){
                   this.$message.success(this.$t('energy.enedata180'));
                   this.visible = false;
                   this.$parent.search();
                } else {
                    this.$message.error(this.$t('energy.enedata181'));
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('saveVirtialPointData',err);
            });
        },
  },
};
</script>
<style scoped>
    #eneVirtialPointDetail{
        height: 100%;
        width:100%;
        position: relative;
    }
</style>