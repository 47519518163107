<!--电能设置--基本设置---->
<template>
  
  <div id="virtialPointIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="header">
      <div class="header-left">
        <div class="header-condition">
          <span>TagPoint：</span>
           <a-input v-model="tagPoint"  :placeholder="getPlaceholder(1,'TagPoint')"  style="width: 150px"/>
        </div>
        <div class="header-condition">
          <span>{{$t('energy.enedata164')}}：</span>
          <a-input v-model="pointName"  :placeholder="getPlaceholder(2,'energy.enedata164')"  style="width: 150px"/>
        </div>
        <div class="header-condition">
          <span>{{$t('energy.enedata165')}}：</span>
          <a-input v-model="unitName"  :placeholder="getPlaceholder(2,'energy.enedata165')"  style="width: 150px"/>
        </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
      </div>
    </div>
    <div class="main" ref="main">
         <!-- :scroll="tscroll" -->
         <a-table 
         :row-selection="rowSelection"
         :columns="columns" 
         :data-source="data"
         :scroll="tscroll"
         :row-key="record => record.id"  
         :pagination="pagination"  
         bordered size="small" >
                <template slot="chaozuo" slot-scope="text, record ">
                    <div class="editable-row-operations">
                        <a-button type="primary" @click="() => editRecord(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                        <a-popconfirm :title="$t('energy.enedata257')" @confirm="() => deleteRecord(record)">
                            <a-button type="danger">{{$t('energy.enedata169')}}</a-button>
                        </a-popconfirm>
                    </div>
                </template>
        </a-table>
    </div>
    <div class="footer">
        <div>
            <a-button type="primary" style="width: 120px" @click="batchDeleta">
               {{$t('energy.enedata169')}}
            </a-button>
            <a-button type="primary" style="width: 120px" @click="pointGrSetup">
            {{$t('energy.enedata190')}}
            </a-button>
        </div>
        <a-button type="primary" style="width: 120px" @click="addPoint">
            {{$t('energy.enedata191')}}
        </a-button>
    </div>
    <ene-virtial-point-detail :data="detail.data" :title="detail.title"></ene-virtial-point-detail>
    <ene-point-gr :data="grset.data" :title="grset.title"></ene-point-gr>
  </div>
</template>



<script>
import { getVirtialPointList ,getVirtialPointData  , saveVirtialPointData ,deleteVirtialPointData} from "../../../api/energy";
import EneVirtialPointDetail from './eneVirtialPointDetail.vue';
import EnePointGR from './enePointGRset.vue';


export default {
  name: "virtialPointIndex",
  data() {
    
    return {
      loading: false,
      data:null,
      columns:[
          {title: 'TagPoint'		, dataIndex: 'tagPoint'		 		 ,width:150},
          {title: this.$t('energy.enedata192')		 , dataIndex: 'entryName'		  		,width:200      },
          {title: 'Gr1'		, dataIndex: 'gr1'				 ,width:150},
          {title: 'Gr2.'		, dataIndex: 'gr2'		 		 ,width:150},
          {title: 'Gr3'		, dataIndex: 'gr3'		 		 ,width:150},
          {title: this.$t('energy.enedata002')		, dataIndex: 'kind'		 	 ,width:150},
          {title: this.$t('energy.enedata165')		, dataIndex: 'unitName'				,width:150},
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],
      page:'4',
      tagPoint:'',
      pointName:'',
      unitName:'',
      pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = 1;
            this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
          },
      },
      selectedRowKeys:[],
      tscroll:{x:700,y:240},
      detail:{
          data:{virtualPoint:{tagPoint:'',name:''}},
          visible:false,
          title:this.$t("energy.enedata256"),//'虚拟点设置',
      },
      grset:{
          data:null,
          visible:false,
          title:this.$t("energy.enedata190"),//'虚拟点设置',
      }
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox',
      };
    },
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
    getPlaceholder(type,code){
      let str = '';
      let msg = this.$t('energy.enedata307');
      if(type===1){
        str = msg.replace("{text}",code);
      }else{
        str = msg.replace("{text}",this.$t(code));
      }
      return str;
    },
       // 表格高度计算
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let width = this.$refs.main.clientWidth;
            let tableHeight = height - 140;
            this.tscroll.y = tableHeight;
            this.tscroll.x = width;
            console.log(width);
            console.log(tableHeight);
            
        }, 0);
        
    },
    editRecord(record){
        this.loadPointInfo(record.id);
    },
    deleteRecord(record){
        let idList = new Array();
        idList.push(record.id);
        this.deletePoint(idList);
    },
    batchDeleta(){
        let len = this.selectedRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata258"));//'请先选择需要删除的虚拟点');
            return ;
        }
        let idList = this.selectedRowKeys;
        this.$confirm({
            title: this.$t('energy.enedata257'),
            centered: true,
            onOk: () => {
                this.deletePoint(idList);
            }
        });
        // this.deletePoint(idList);
    },
    deletePoint(idList){
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            idList:idList,

        };
       this.loading = true;
       try{
            console.log("DeletePoint Params",params);
            deleteVirtialPointData(params)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '05'){
                    this.$message.success(this.$t("energy.enedata182"));
                    this.search();
                  
                } else {
                    this.$message.error(this.$t("energy.enedata183"));
                }
                  this.loading = false;
            })
            .catch((err) => {
                console.log('deleteVirtialPointData',err);
                  this.loading = false;
            });
       }catch(e){
            console.log('ddddddddddddddddddddddddddd',e);
              this.loading = false;
       }
    },
    addPoint(){
        this.loadPointInfo(0);
    },
    loadPointInfo(id){
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            id:id,

        };
       this.loading = true;
       try{
            console.log("AddPoint Params",params);
            getVirtialPointData(params)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res!=null && res.errorCode === '00'){
                    this.detail.data = res.data;
                    this.detail.visible = true;
                }else{
                     this.$message.error(this.$t("energy.enedata138"));
                } 
                this.loading = false;
            })
            .catch((err) => {
                console.log('getVirtialPointData',err);
                this.loading = false;
            });
       } catch(e){
            console.log('ddddddddddddddddddddddddddd',e);
            this.loading = false;
       }
    },
    pointGrSetup(){
        this.grset.visible = true;
    },
    search(){
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            tagPoint:this.tagPoint,
            entryName:this.pointName,
            unitName:this.unitName,
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getVirtialPointList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.data = res.data;
                 this.tableSize();
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    handleTableChange(){

    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
  components: {
    'ene-virtial-point-detail':EneVirtialPointDetail,
    'ene-point-gr':EnePointGR,
  },
};
</script>

<style scoped>

#virtialPointIndex {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.headertitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    height: calc(100% - 120px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>


