<!--电能设置--计量点管理---->
<template>
  <div id="enekind" v-loading="loading" :element-loading-text="$t('alarm.a0')">

    <!-- 条件选择查询 -->
    <div class="display-between">
      <div class="display-between condition">       
        <ene-header :eneKinds="eneKinds" :title="$t('energy.enedata060')" :fatherChangeMethod="handleKindChange"></ene-header>
      </div>
      <a-button type="primary" @click="searchEneKindData">{{$t('energy.enedata006')}}</a-button>
    </div>
    <!-- 分割线 -->
    <div class="line"></div>

     <div>
        <a-row>
            <a-col :span="11">
                <div>
                    <div class="display-between condition-two">
                      <div class="display-column">
                        <span>{{$t('energy.enedata165')}}</span>
                        <a-input v-model="data.unit" :disabled="0==0"></a-input>
                      </div>
                      <div class="display-column">
                        <span>Gr1</span>
                        <a-select v-model="data.gr1" style="width: 130px" @change="handleGrChange(1)">
                          <a-select-option
                              v-for="(itme, index) in data.gr1s"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                      <div class="display-column">
                        <span>Gr2</span>
                        <a-select v-model="data.gr2" style="width: 130px" @change="handleGrChange(2)">
                          <a-select-option
                              v-for="(itme, index) in data.gr2s"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                      <div class="display-column">
                        <span>Gr3</span>
                        <a-select v-model="data.gr3" style="width: 130px" @change="handleGrChange(3)">
                          <a-select-option
                              v-for="(itme, index) in data.gr3s"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <!-- 显示内容 -->
                    <ene-point-table :data="data.pointList" :fatherChangeMethod="getSelectedPoint" :Yscroll="pointYscroll"></ene-point-table>
                </div>
            </a-col>
            <a-col :span="2">
              <div>
                  <div class="display-center button">
                    <a-button type="primary" @click="addSelected" :disabled="!addSelPoint">{{$t('energy.enedata212')}}</a-button>
                  </div>
                  <div class="display-center button">
                    <a-button type="primary" ghost @click="removeSelected" :disabled="!delSelPoint">{{$t('energy.enedata213')}}</a-button>
                  </div>
              </div>

            </a-col>
             <a-col :span="11">
               <div>
                   <!-- 页面标题 -->
                  <div class="headertitle" style="margin:10px 0;">
                    <span>{{$t('energy.enedata231')}}</span>
                  </div>
                  <!-- 条件选择2 -->
                  <div class="display-between condition-two">
                      
                      <div class="display-column">
                        <span>{{$t('energy.enedata232')}}</span>
                        <a-select v-model="data.measurement" style="width: 130px" @change="handleChange" :disabled="levelenable">
                        <a-select-option
                              v-for="(itme, index) in data.measurementList"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                      <div class="display-column">
                        <span>{{$t('energy.enedata233')}}</span>
                        <a-select v-model="data.summer" style="width: 130px" @change="handleChange" :disabled="levelenable || data.measurement==='1' || data.measurement==='0'">
                          <a-select-option
                              v-for="(itme, index) in data.dateList"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                      <div class="display-column">
                        <span>{{$t('energy.enedata234')}}</span>
                        <a-select v-model="data.winter" style="width: 130px" @change="handleChange" :disabled="levelenable || data.measurement==='1' || data.measurement==='0'">
                          <a-select-option
                              v-for="(itme, index) in data.dateList"
                              :key="index"
                              :value="itme.no"
                            >
                              {{ itme.text }}
                            </a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <ene-point-table :data="data.choicePointList" :fatherChangeMethod="getDeletedPoint" :Yscroll="chcPointYscroll"></ene-point-table>
               </div>
            </a-col>
        </a-row>
     </div>
    <!-- 条件选择2 -->
      

    

    
    <!-- 显示内容 -->
    <div class="display-center button">
      <a-button type="primary" @click="saveData" :disabled="!dataChange">{{$t('energy.enedata173')}}</a-button>
    </div>
  </div>
</template>

<script>

import { getEneKindSetup } from "../../../api/energy";
import EneHeader from "../components/measurePoint/eneKindHeader.vue";
import EnePointTable from "../components/measurePoint/eneMeasurePointTable.vue";
export default {
  data() {
    return {
      loading:false,
      eneKinds:[],
      kind:'',
      data:{
          measurementList:[],
          measurement:'',
          dateList:[],
          summer:'',
          winter:'',
          choicePointList:[],
          gr1:'',
          gr1s:[],
          gr2:'',
          gr2s:[],
          gr3:'',
          gr3s:[],
          pointList:[],
          unit:'',
      },
      selectedPoint:[],
      deletedPoint:[],
      dataChange:false,
      addSelPoint:false,
      delSelPoint:false,
      chcPointYscroll:{y:320},
      pointYscroll:{y:360},
      page:'2',
      levelenable:true,
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    // if(this.$store.getters.clientId){
    //     console.log("activated load data");
    //     this.initPage();
    // }
  },
  mounted() { 
    console.log("mounted",this.$store.getters.clientId);
    this.initPage();
  },
  methods:{
    getSelectedPoint(selectedRowKeys){
      console.log('getSelectedPoint changed: ', selectedRowKeys);
      this.selectedPoint = selectedRowKeys;
      if(this.selectedPoint.length>0){
        this.addSelPoint = true;
      }else{
        this.addSelPoint = false;
      }
    },
    getDeletedPoint(selectedRowKeys){
      console.log('getDeletedPoint changed: ', selectedRowKeys);
      this.deletedPoint = selectedRowKeys;
       if(this.deletedPoint.length>0){
        this.delSelPoint = true;
      }else{
        this.delSelPoint = false;
      }
    },
    removeSelected(){
      let tmpPointList = [];
      let err = 0;
      for (let index = 0; index < this.data.choicePointList.length; index++) {
        const element = this.data.choicePointList[index];
        let e = true;
        for(let k=0;k<this.deletedPoint.length;k++){
          if(element.id===parseInt(this.deletedPoint[k])){
            if(''===element.msg){
              e = false;
            }else{
              err = 1
              this.$message.error(element.msg);
            }
            break;
          }
        }
        if(err>0){
          break;
        }
        console.log(""+index,e);
        if(e){         
          tmpPointList.push(element);
        }
      }
      if(err==0){
        this.dataChange = true;
        this.data.choicePointList = tmpPointList;
      }
    },
    addSelected(){
      let tmpPointList = [];
      let tmpPointList2 = this.data.choicePointList;
       for (let index = 0; index <  this.data.pointList.length; index++) {
        const element = this.data.pointList[index];
        let e = false;
        for(let k=0;k<this.selectedPoint.length;k++){
           if(element.id===parseInt(this.selectedPoint[k])){
            e = true;
            break;
          }
        }
         if(e){
          tmpPointList.push(element);
        }
      }
      for(let k=0;k<tmpPointList.length;k++){
        const element = tmpPointList[k];
        let e = true;
        for (let index = 0; index <  this.data.choicePointList.length; index++) {
           if(element.id===this.data.choicePointList[index].id){
            e = false;
            break;
          }
        }
        if(e){
          this.dataChange = true;
          tmpPointList2.push(element);
        }
      }
       for(let k=0;k<tmpPointList2.length;k++){
          tmpPointList2[k].no=k+1;
       }
      this.data.choicePointList = tmpPointList2;
    },
    handleKindChange(val){
      console.log("handleKindChange",val);
      this.kind = val;
      if(""!==val){
          let dd = val.split(";");
          let no = parseInt(dd[0]);
          if(no>0 && no <6){
            this.levelenable = false;
          }else{
            this.levelenable = true;
          }
      }else{
          this.levelenable = true;
      }
    },
     handleChange(val){
      console.log("handleChange",val);
      this.dataChange = true;
      if(val === '0'){
        this.data.summer='0';
        this.data.winter='0';
      } else if(val === '1'){
        this.data.summer = this.data.dateList[this.data.dateList.length-1].no;
        this.data.winter = this.data.dateList[this.data.dateList.length-1].no;
      }
    },
    handleGrChange(gr){
      if(gr==1){
        this.data.gr2 = "0";
        this.data.gr3 = "0";
      }else if(gr==2){
        this.data.gr3 = "0";
      }

      let params =  {
          action:2,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          energyKind:this.kind,
          gr1:this.data.gr1,
          gr2:this.data.gr2,
          gr3:this.data.gr3,
      };
      console.log("GrChange Params",params);
      this.loadData(params,this.initHandler);
    },
    initPage(){

       let params =  {
          action:0,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      console.log("InitPage Params",params);
      this.loadData(params,this.initHandler);
    },
    initHandler(action,result){
      console.log("InitPage Data",result);
      if(action==0){
          this.eneKinds = result.energyKinds;
      }else if(action==1){
          console.log("Search Data",result);
          let tmpPointList = [];
          for (let index = 0; index < result.choicePointList.length; index++) {
            const element = result.choicePointList[index];
            element.key=''+element.id;
            element.no=index+1;
            tmpPointList.push(element);
          }
          this.data = result;
          this.data.choicePointList = tmpPointList;
          this.measurement = this.data.measurementList[3].no;
          //this.delSelPoint = true;
      }else if(action==2){
          this.data.gr1s = result.gr1s;
          this.data.gr2s = result.gr2s;
          this.data.gr3s = result.gr3s;
          let tmpPointList = [];
          for (let index = 0; index < result.pointList.length; index++) {
            const element = result.pointList[index];
            element.key=''+element.id;
            element.no=index+1;
            tmpPointList.push(element);
          }
          this.data.pointList = tmpPointList;
          //this.addSelPoint = true;
      }else if(action==3){
        // this.$message.success(result.msg);
        this.dataChange = false;
        this.searchEneKindData(); 
        // if(result.errorCode=='00' || result.errorCode=='03'){
        // }else{
        //     this.$message.error(result.msg);
        // }
      }
    },
    searchEneKindData(){
      if(this.kind==''){
          this.$message.error(this.$t('energy.enedata237'));
          return;
      }
      let params =  {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId:   this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          energyKind:this.kind,
      };
       console.log("Search Params",params);
      if(this.dataChange){
         this.$confirm({
            title: this.$t('energy.enedata238'),//'确认要放弃所编辑的能源类型计量对象信息吗？',
            centered: true,
            onOk: () => {
              this.loadData(params,this.initHandler);
            },
          });
      }else{
        this.loadData(params,this.initHandler);
      }
     
    },
    saveData(){
      let params =  {
          action:3,
          clientId: this.$store.getters.clientId,
          siteId:   this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          energyKind:this.kind,
          measurement:this.data.measurement,
          summer:this.data.summer,
          winter:this.data.winter,
          choicePointList:this.data.choicePointList,
      };
      console.log("SaveData Params",params);
       this.$confirm({
        title: this.$t('energy.enedata239'),//'确认要保存所设置的能源类型计量对象信息吗？',
        centered: true,
        onOk: () => {
          this.loadData(params,this.initHandler);
          
        },
      });

    },
    loadData(params,func){
     
      this.loading = true;
      getEneKindSetup(params)
      .then((res) => {
        console.log("LoadData Result",res);
        if(res.errorCode == '00'){
          func(params.action,res.data);
          if(params.action===3){
            this.$message.success(res.msg);
          }
        } else {
          if(params.action===3){
            this.$message.error(res.msg);
          }
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    }
  },
  components: {
    'ene-header':EneHeader,
    'ene-point-table':EnePointTable,
  },
};
</script>

<style scoped>
#enekind {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  overflow-y: auto;
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.header-title::before {
  content: "";
  display: inline-block;
  height: 30px;
  width: 4px;
  background: #7585cd;
  margin-right: 20px;
}
.display-between {
  display: flex;
  justify-content: space-between;
}
.display-between-end{
    align-items: flex-end;
}
.display-center{
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.condition {
  white-space: nowrap;
  overflow: hidden;
}
.condition-two {
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  margin: 12px 0px 8px 0px;
}

.button{
  padding: 8px 0;
}
.button button{
  width: 120px;
  margin: 0 12px;
}
</style>