<!--电能设置--计量点管理---->
<template>
  <div id="ownTenDiv" v-loading="loading" :element-loading-text="$t('alarm.a0')">
 
    <!-- 条件选择查询 -->
    <div class="display-between">
    <ene-header :eneKinds="data.measurementObjectList" :tenantList="data.tenantList" :yearList="data.yearList" :year="data.year"></ene-header>
    <a-button type="primary" @click="searchData">{{ $t('energy.enedata006') }}</a-button>
    </div>
    <!-- 分割线 -->
    <div class="line"></div>
    <a-row :gutter="16">
      <a-col :span="6">
        <div  :class="data.allUsePointClass">
            <div class="card-title">
              <a-icon type="check-circle" style="margin-right:5px;"/>
              <span>{{$t('energy.enedata286')}}</span>
            </div>
            <div class="card-info" >
              <div  style="width: 100%;text-align: center;">
                <a-select style="width: 150px;" :placeholder="$t('energy.enedata060')"
                      v-model="data.allUsePointId"  
                      :disabled="data.allUsePointDisabled"  
                      @select="handlePointChange($event,1)" >
                    <a-select-option
                      v-for="(itme, index) in data.pointList"
                      :key="index"
                      :value="itme.id">{{ itme.pointNo }} {{ itme.pointName }}</a-select-option>
                </a-select>

              </div>
              <ene-own-ten-card-detail :pointInfo="data.allUsePoint"  ></ene-own-ten-card-detail>
            </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div  :class="data.tenantPointClass">
            <div class="card-title">
              <a-icon type="check-circle" style="margin-right:5px;"/>
              <span>{{$t('energy.enedata287')}}</span>
            </div>
            <div class="card-info" >
              <div  style="width: 100%;text-align: center;">
                <a-select style="width: 150px;" :placeholder="$t('energy.enedata217')"
                      v-model="data.tenantPointId"  
                      :disabled="data.tenantPointDisabled"  
                      @select="handlePointChange($event,2)" >
                    <a-select-option
                      v-for="(itme, index) in data.pointList"
                      :key="index"
                      :value="itme.id">{{ itme.pointNo }} {{ itme.pointName }}</a-select-option>
                </a-select>

              </div>
              <ene-own-ten-card-detail :pointInfo="data.tenantPoint"  ></ene-own-ten-card-detail>
            </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div  :class="data.ownTenDivPointClass">
            <div class="card-title">
              <a-icon type="check-circle" style="margin-right:5px;"/>
              <span>{{$t('energy.enedata269')}}</span>
            </div>
            <div class="card-info" >
              <div  style="width: 100%;text-align: center;">
                <a-select style="width: 150px;" :placeholder="$t('energy.enedata217')"
                      v-model="data.ownTenDivPointId"  
                      :disabled="data.ownTenDivPointDisabled"  
                      @select="handlePointChange($event,3)" >
                    <a-select-option
                      v-for="(itme, index) in data.pointList"
                      :key="index"
                      :value="itme.id">{{ itme.pointNo }} {{ itme.pointName }}</a-select-option>
                </a-select>

              </div>
              <ene-own-ten-card-detail :pointInfo="data.ownTenDivPoint"  ></ene-own-ten-card-detail>
            </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div  :class="data.chargePointClass">
            <div class="card-title">
              <a-icon type="check-circle" style="margin-right:5px;"/>
              <span>{{$t('energy.enedata270')}}</span>
            </div>
            <div class="card-info" >
              <div  style="width: 100%;text-align: center;">
                <a-select style="width: 150px;" :placeholder="$t('energy.enedata217')"
                      v-model="data.chargePointId"  
                      :disabled="data.chargePointDisabled"  
                      @select="handlePointChange($event,4)" >
                    <a-select-option
                      v-for="(itme, index) in data.chargePointList"
                      :key="index"
                      :value="itme.id">{{ itme.pointNo }} {{ itme.pointName }}</a-select-option>
                </a-select>

              </div>
              <ene-own-ten-card-detail :pointInfo="data.chargePoint"  ></ene-own-ten-card-detail>
            </div>
        </div>
      </a-col>
     
    </a-row> 

    <div class="display-center button">
      <a-button type="primary" @click="saveData" :disabled="!dataChange">{{ $t('energy.enedata173') }}</a-button>
    </div>
  </div>
</template>

<script>

import { getEneOwnTenDivSetup } from "../../../api/energy";
import EneHeader from "../components/measurePoint/eneOwnTenDivHeader.vue";
import EneOwnTenCardDetail from "../components/measurePoint/eneOwnTenCardDetail.vue";

export default {
  data() {
    return {
      loading:false,
      data:{
        selTenantId: "0",
        measurementObject: "0",
        year: 2020,
        tenantList: [],
        measurementObjectList: [],
        yearList: [],
        eneType: "0",
        eneTypeList: [],
        ownTenDiv: "0",
        ownTenDivList: [],
        pointList: [{id:0,pointName:this.$t('energy.enedata217'),pointNo:''}],
        chargePointList: [{id:0,pointName:this.$t('energy.enedata217'),pointNo:''}],
        allUsePoint: {id:0},
        tenantPoint: {id:0},
        ownTenDivPoint: {id:0},
        chargePoint: {id:0},
        charge: false,
        allUsePointClass 	: 'card',
        tenantPointClass 	: 'card',
        ownTenDivPointClass : 'card',
        chargePointClass 	: 'card',
        allUsePointId 	: 0,
        tenantPointId 	: 0,
        ownTenDivPointId 	: 0,
        chargePointId 	: 0,
        allUsePointDisabled:true,
        tenantPointDisabled:true,
        ownTenDivPointDisabled:true,
        chargePointDisabled:true,
      },
      dataChange:false,
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.initPage();
    }
  },
  async mounted() { 
    this.initPage();
  },
  methods:{
    checkExistPoint(point,existPoint1,existPoint2){
      let err = false;
      if(!point){
        return true;
      }
      if(point.id==0){
        return err;
      }
      if(existPoint1){
        if(existPoint1.id!=0 && point.id===existPoint1.id){
          return true;
        }
      }
      if(existPoint2){
        if(existPoint2.id!=0 && point.id===existPoint2.id){
          return true;
        }
      }
      return err;
    },
    handlePointChange(id,eneType){
      let err = false;
      console.log("id>>>",id);
      if(id==0){
          const point = this.data.pointList[0];
          switch(parseInt(eneType)){
            case 1:
              this.data.allUsePoint = point;
              this.data.allUsePointClass = 'card';
              break;
            case 2:
              this.data.tenantPoint = point;
              this.data.tenantPointClass = 'card';
              break;
            case 3:
              this.data.ownTenDivPoint = point;
              this.data.ownTenDivPointClass = 'card';
              break;
            case 4:
              this.data.chargePoint = point;
              this.data.chargePointClass = 'card';
              break;
          }
      }else{
        let point = null;
        let et = parseInt(eneType);
        if(et===4){
          for(let i=0;i< this.data.chargePointList.length;i++) {
            const element = this.data.chargePointList[i];
            if(element.id===id){
                point = element;
                break;
            }
          }
        }else{
          for(let i=0;i< this.data.pointList.length;i++) {
            const element = this.data.pointList[i];
            if(element.id===id){
                point = element;
                break;
            }
          }
        }
        console.log("point",point);
        if(point==null)
          return true;
        switch(et){
          case 1:
              err = this.checkExistPoint(point,this.data.tenantPoint,this.data.ownTenDivPoint);
              if(err){
                this.data.allUsePointId = this.data.allUsePoint==null?0:this.data.allUsePoint.id;
                this.data.allUsePointClass = 'card';
             //   this.data.allUsePoint = this.data.pointList[0];
              }else{
                this.data.allUsePoint = point;
                this.data.allUsePointClass = 'card card-selected';
              }
              break;
          case 2:
              err = this.checkExistPoint(point,this.data.allUsePoint,this.data.ownTenDivPoint);
              if(err){
                this.data.tenantPointId = this.data.tenantPoint==null?0:this.data.tenantPoint.id;
                this.data.tenantPointClass = 'card';
               //  this.data.allUsePoint = this.data.pointList[0];
              }else{
                this.data.tenantPoint = point;
                this.data.tenantPointClass = 'card card-selected';
              }
              break;
          case 3:
              err = this.checkExistPoint(point,this.data.tenantPoint,this.data.allUsePoint);
            if(err){
                this.data.ownTenDivPointId = this.data.ownTenDivPoint==null?0:this.data.ownTenDivPoint.id;
                this.data.ownTenDivPointClass = 'card';
               //  this.data.allUsePoint = this.data.pointList[0];
              }else{
                this.data.ownTenDivPoint = point;
                this.data.ownTenDivPointClass = 'card card-selected';
              }
              break;
          case 4:
              err = this.checkExistPoint(point,this.data.chargePoint,null);
              if(err){
                this.data.chargePointId = this.data.chargePoint==null?0:this.data.chargePoint.id;
                this.data.chargePointClass = 'card';
                //this.data.allUsePoint = this.data.pointList[0];
              }else{
                this.data.chargePoint = point;
                this.data.chargePointClass = 'card card-selected';
              }
            break;
        }
      }

      if(err){
        this.$message.error(this.$t('energy.enedata288'));//'所选择设备计量点已被注册');
      }else{
        this.dataChange = true;
      }
      return err;
    },
    initPage(){
       let params =  {
          action:0,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      this.loadData(params,this.initHandler);
    },
    initHandler(action,result){
      if(action==0){
          console.log("InitPage Data",result);
          this.data.tenantList = result.tenantList;
          this.data.yearList = result.yearList;
          this.data.measurementObjectList = result.measurementObjectList;
          this.data.measurementObject = result.measurementObject;
          this.data.year = result.year;
          this.data.selTenantId = result.selTenantId+"";
      }else if(action==1){
          console.log("Search Data",result);

          if(result.pointList.length==0){
             this.$message.error(this.$t('energy.enedata289'));//'还没有相应能源使用类型的设备信号点；请在运算点管理中进行注册，并在能源类型中进行设置。');
             return;
          }

          let tmpPointList = [{id:0,pointName:this.$t('energy.enedata217'),pointNo:''}];
          for (let index = 0; index < result.pointList.length; index++) {
            const element = result.pointList[index];
            tmpPointList.push(element);
          }
          this.data.pointList = tmpPointList;
          this.data.eneType = result.eneType;
          this.data.eneTypeList = result.eneTypeList;
          this.data.ownTenDiv = result.ownTenDiv;
          this.data.ownTenDivList = result.ownTenDivList;
          
          this.data.allUsePointClass = 'card';
		      this.data.tenantPointClass = 'card';
		      this.data.ownTenDivPointClass = 'card';
          this.data.chargePointClass = 'card';
          
          
          for (let i = 0; i < result.ownTenDivList.length; i++) {
            switch(parseInt(result.ownTenDivList[i].no)){
            case 1:
              this.data.allUsePointDisabled = false;
              break;
            case 2:
              this.data.tenantPointDisabled = false;
              break;
            case 3:
              this.data.ownTenDivPointDisabled = false;
              break;
            case 4:
              break;
          }
          }
        		  
          if(result.chargePointList  != null ){
            let tmpChargePointList = [{id:0,pointName:this.$t('energy.enedata217'),pointNo:''}];
            for (let index = 0; index < result.chargePointList.length; index++) {
              const element = result.chargePointList[index];
              tmpChargePointList.push(element);
            }
            this.data.chargePointList = tmpChargePointList	;
            this.data.chargePointDisabled = false;
          }

          if(result.allUsePoint	 	 != null ){
            this.data.allUsePoint		= result.allUsePoint		;
            this.data.allUsePointClass = 'card card-selected';
            this.data.allUsePointId = result.allUsePoint.id;
          }else{
            this.data.allUsePoint	= {id:0};
            this.data.allUsePointId = 0;
          }

          if(result.tenantPoint	 	 != null ){
            this.data.tenantPoint		= result.tenantPoint		;
            this.data.tenantPointClass = 'card card-selected';
            this.data.tenantPointId  = result.tenantPoint.id;
          }else{
            this.data.tenantPoint	= {id:0};
            this.data.tenantPointId = 0;
          }

          if(result.ownTenDivPoint	 != null ){
            this.data.ownTenDivPoint	= result.ownTenDivPoint		;
            this.data.ownTenDivPointClass = 'card card-selected';
            this.data.ownTenDivPointId  = result.ownTenDivPoint.id;
          }else{
            this.data.ownTenDivPoint	= {id:0};
            this.data.ownTenDivPointId = 0;
          }

          if(result.chargePoint != null ){
            this.data.chargePoint	= result.chargePoint		;
            this.data.chargePointClass = 'card card-selected';
            this.data.chargePointId  = result.chargePoint.id;
          }else{
            this.data.chargePoint	= {id:0};
            this.data.chargePointId = 0;
          }

      }else if(action==3){
          
          this.dataChange = false;
          this.searchData(); 
      }
    },
    searchData(){
      
      if(this.data.selTenantId===''){
          this.$message.error(this.$t('energy.enedata290'));//'请选择建筑/租户');
          return;
      }
      if(this.data.measurementObject==='0'){
          this.$message.error(this.$t('energy.enedata237'));//'请选择能源类型');
          return;
      }
      if(this.data.year===''){
          this.$message.error(this.$t('energy.enedata291'));//'请选择目标年度');
          return;
      }

      let params =  {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId:   this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          enekind: this.data.measurementObject,
          selTenantId: this.data.selTenantId,
          year: this.data.year
      };
       console.log("Search Params",params);
      if(this.dataChange){
         this.$confirm({
            title: this.$t('energy.enedata238'),//'确认要放弃所编辑的能源类型计量对象信息吗？',
            centered: true,
            onOk: () => {
              this.loadData(params,this.initHandler);
            },
          });
      }else{
        this.loadData(params,this.initHandler);
      }
     
    },
    saveData(){
       let ownTenDivPoint = this.data.ownTenDivPoint.id>0?this.data.ownTenDivPoint:null;
       let chargePoint = this.data.chargePoint.id>0?this.data.chargePoint:null;
       let tenantPoint = this.data.tenantPoint.id>0?this.data.tenantPoint:null;
       let allUsePoint = this.data.allUsePoint.id>0?this.data.allUsePoint:null;
       let params =  {
          action:3,
          clientId: this.$store.getters.clientId,
          siteId:   this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          enekind: this.data.measurementObject,
          selTenantId: this.data.selTenantId,
          year: this.data.year,
          allUsePoint:allUsePoint,
          tenantPoint:tenantPoint,
          chargePoint:chargePoint,
          ownTenDivPoint:ownTenDivPoint,
      };
      console.log("SaveData Params",params);
       this.$confirm({
        title: this.$t('energy.enedata239'),//'确认要保存所设置的能源类型计量对象信息吗？',
        centered: true,
        onOk: () => {
          this.loadData(params,this.initHandler);
        },
      });

    },
    loadData(params,func){
     
      this.loading = true;
      getEneOwnTenDivSetup(params)
      .then((res) => {
        console.log("LoadData Result",res);
        if(res.errorCode == '00'){
            func(params.action,res.data);
        } 
        if(params.action==3){
            if(res.errorCode == '00'){
                this.$message.success(res.msg);
            }else{
                this.$message.error(res.msg);
            }
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    }
  },
  components: {
    'ene-header':EneHeader,
    'ene-own-ten-card-detail':EneOwnTenCardDetail,
  },
};
</script>

<style scoped>
#ownTenDiv {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.display-center{
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
   align-items: flex-start;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.condition {
  width: 600px;
  white-space: nowrap;
  overflow: hidden;
}
.condition-two {
  width: 450px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  margin: 30px 0px 20px 0px;
}
.button{
  padding: 16px 0;
}
.button button{
  width: 120px;
  margin: 0 12px;
}
.card{
  color: #b5b5b5;
  border: 1px solid #b5b5b5;
  width: 100%;
  height: 300px;
  font-family: "Microsoft YaHei";
  font-size: 14px;
}
.card:hover{
  color: #7682ce;
  border: 1px solid #7682ce;
}
.card-selected{
  color: #7682ce;
  border: 1px solid #7682ce;
  
}
.card-title{
  padding: 5px;
  margin: 5px;
  font-size: 20px;
}
.card-info{
  border-top: 1px solid #b5b5b5;
  padding: 5px;
  margin: 5px;
}
</style>