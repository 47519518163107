<!--电能设置--计量点管理---->
<template>
  <div id="measure" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div class="display-between" style="margin-bottom: 10px;">
      <div class="headertitle display-between">
        <span>{{$t('energy.enedata292')}}</span>
      </div>
      <ene-point-menu :title="$t('energy.enedata293')" :vale="page"></ene-point-menu>
    </div>
    <ene-kind v-if="page==='1'"></ene-kind>
    <ene-own-ten v-else-if="page==='2'"></ene-own-ten>
    <ene-measure-obj v-else-if="page==='3'"></ene-measure-obj>
    <ene-virtial-point v-else-if="page==='4'"></ene-virtial-point>
  </div>
</template>

<script>

import EnePointMenu from "../components/measurePoint/eneMeasurePointMenu.vue";
import EneKind from "./eneKind.vue";
import EneOwnTenDiv from "./eneOwnTenDivison.vue";
import EneMeasureObject from "./eneMeasureObject.vue";
import EneVirtialPoint from "./eneVirtialPoint.vue";
export default {
  data() {
    return {
      loading:false,
      page:'1',
    };
  },
  computed: {
    
  },
  async mounted() { 
   
  },
  methods:{
    
  },
  components: {
    'ene-point-menu':EnePointMenu,
    'ene-kind':EneKind,
    'ene-own-ten':EneOwnTenDiv,
    'ene-measure-obj':EneMeasureObject,
    'ene-virtial-point':EneVirtialPoint,
  },
};
</script>

<style scoped>
#measure {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.headertitle {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.display-between {
  display: flex;
  justify-content: space-between;
}
.display-between-end{
    align-items: flex-end;
}
</style>