<template>
  <a-table
    :row-selection="rowSelection"
    :columns="columns"
    :data-source="data"
    :pagination="pagination"
    :scroll="Yscroll"
    size="small"
  />
</template>
<script>


export default {
  name: 'EnePointTable',
  props: {
    title: {
      type: String,
      default: "能源类型",
    },
    data: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
    checkType:{//多选/单选，checkbox or radio
       type: String,
       default: "checkbox",
    },
    Yscroll:{
       type: Object,
       default: { y: 200 },
    }
  }, 
  data() {
    return {
      columns:[
        {
          title: 'NO.',
          dataIndex: 'no',
          width: 60,
        },
        {
          title: this.$t('energy.enedata235'),//'计量信号编号',
          dataIndex: 'pointNo',
          width: 150,
        },
        {
          title: this.$t('energy.enedata236'),//'计量信号名称',
          dataIndex: 'pointName',
        },
        {
          title: this.$t('energy.enedata165'),//'单位',
          dataIndex: 'unit',
          width: 100,
        },
        
      ],
      selectedRowKeys:[],
      pagination:false,
    };
  },
  watch:{
    "data":{
      handler(val){
        this.selectedRowKeys = [];
      },
      deep:true,
      immediate:true,
      
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:this.checkType,
      };
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      if(this.fatherChangeMethod){
          this.fatherChangeMethod(selectedRowKeys)
      }
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>
