<!--电能设置--计量对象区分---->
<template>
  <div id="eneMeasureObject" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 条件选择查询 -->
    <div class="display-between">
      <div class="display-between condition">       
        <ene-header :measureObjects="data.measurementObjectList" :tenantList="data.tenantList" :yearList="data.dateList" :year="data.myyear"></ene-header>
      </div>
      <a-button type="primary" @click="searchEneMeasureObjectData">{{$t('energy.enedata006')}}</a-button>
    </div>
    <!-- 分割线 -->
    <div class="line"></div>
    <div>
        <a-row>
            <a-col :span="11">
               <!-- 条件选择2 -->
                <div class="display-between condition-two">
                    <div class="display-column">
                        <span>{{$t('energy.enedata060')}}</span>
                        <a-select v-model="data.eneType" style="width: 130px" @change="handleEneChange(1)">
                        <a-select-option
                            v-for="(itme, index) in data.eneTypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="display-column">
                        <span>{{$t('energy.enedata165')}}</span>
                        <a-select v-model="data.unit" style="width: 130px" @change="handleEneChange(2)">
                            <a-select-option
                                v-for="(itme, index) in data.unitList"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="display-column">
                        <span>{{$t('energy.enedata294')}}</span>
                        <a-select v-model="data.ownTenDiv" style="width: 130px" :disabled="data.bolOwnTenDiv" @change="handleEneChange(3)">
                        <a-select-option
                            v-for="(itme, index) in data.ownTenDivList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div>
                    <!-- 显示内容 -->
                    <ene-point-table :data="data.pointList" :fatherChangeMethod="getSelectedPoint" :Yscroll="pointYscroll"></ene-point-table>  
                </div>
            </a-col>
            <a-col :span="2">
                 <div style="margin:40px 0;padding:40px 0;">
                     <div class="display-center" style="padding:4px;">
                        <a-button type="primary" @click="addSelected" :disabled="!addSelPoint">{{$t('energy.enedata212')}}</a-button>
                    </div>
                    <div class="display-center" style="padding:4px;">
                        <a-button type="primary" ghost @click="removeSelected" :disabled="!delSelPoint">{{$t('energy.enedata213')}}</a-button>
                     </div>
                </div>
            </a-col>
            <a-col :span="11">
               <div>
                   <div style="font-size:14px;padding:4px 0;">{{$t('energy.enedata286')}}</div>
                    <ene-point-table :data="data.allUsePointList" :fatherChangeMethod="getDeletedAllUsedPoint" :Yscroll="allUseYscroll"></ene-point-table>
                </div>
                <div>
                    <div style="font-size:14px;padding:4px 0;">{{$t('energy.enedata294')}}</div>
                    <ene-point-table :data="data.ownTenDivPointList" :fatherChangeMethod="getDeletedOwnTenPoint" :Yscroll="ownTenYscroll"></ene-point-table>
                </div>
            </a-col>
        </a-row>
    </div>
        
    <!-- 显示内容 -->
    <div class="display-center button">
      <a-button type="primary" @click="saveData" :disabled="!dataChange">{{$t('energy.enedata173')}}</a-button>
    </div>
  </div>
</template>

<script>

import { getEneMeasureObjectSetup } from "../../../api/energy";
import EneHeader from "../components/measurePoint/eneMeasureObjectHeader.vue";
import EnePointTable from "../components/measurePoint/eneMeasurePointTable.vue";
export default {
  data() {
    return {
      loading:false,
      kind:'',
      data:{
          measurementObjectList:[],
          dateList:[],
          tenantList:[],
          myyear:0,
          measurementObject:'',
          selTenantId:0,
          allUsePointList:[],
          ownTenDivPointList:[],
          eneType:'',
          eneTypeList:[],
          unit:'',
          unitList:[],
          eneTypeWithUnitList:[],
          eneTypeUnitMap:null,
          ownTenDiv:'',
          ownTenDivList:[],
          pointList:[],
          bolOwnTenDiv:false,
          choicePointList:[],
          gr1:'',
          gr1s:[],
          gr2:'',
          gr2s:[],
          gr3:'',
          gr3s:[],
      },
      selectedPoints:[],
      deletedAllUsedPoints:[],
      deletedOwnTenPoints:[],
      dataChange:false,
      addSelPoint:false,
      delSelPoint:false,
      allUseYscroll:{y:180},
      ownTenYscroll:{y:180},
      pointYscroll:{y:400},
      page:'3',
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.initPage();
    }
  },
  async mounted() { 
    this.initPage();
  },
  methods:{
    getSelectedPoint(selectedRowKeys){
      console.log('getSelectedPoint changed: ', selectedRowKeys);
      this.selectedPoints = selectedRowKeys;
      if(this.selectedPoints.length>0){
          this.addSelPoint = true;
      }else  {
          this.addSelPoint = false;
      }
    },
    getDeletedAllUsedPoint(selectedRowKeys){
      console.log('getDeletedPoint changed: ', selectedRowKeys);
      this.deletedAllUsedPoints = selectedRowKeys;
      if(this.deletedAllUsedPoints.length>0 || this.deletedOwnTenPoints.length>0){
          this.delSelPoint = true;
      }else{
          this.delSelPoint = false;
      }
    },
    getDeletedOwnTenPoint(selectedRowKeys){
      console.log('getDeletedPoint changed: ', selectedRowKeys);
      this.deletedOwnTenPoints = selectedRowKeys;
      if(this.deletedAllUsedPoints.length>0 || this.deletedOwnTenPoints.length>0){
          this.delSelPoint = true;
      }else{
          this.delSelPoint = false;
      }
    },
    removeSelected(){
        if(this.deletedAllUsedPoints.length>0){
            let tmpPointList = [];
            let len = this.data.allUsePointList.length;
            for (let index = 0; index < len; index++) {
                const element = this.data.allUsePointList[index];
                let e = true;
                for(let k=0;k<this.deletedAllUsedPoints.length;k++){
                    if(element.id===parseInt(this.deletedAllUsedPoints[k])){
                        e = false;
                        break;
                    }
                }
                console.log(""+index,e);
                if(e){
                    tmpPointList.push(element);
                }
            } 
            if(len != tmpPointList.length){
                this.dataChange = true;
            }  
            this.data.allUsePointList = tmpPointList;
        }
        if(this.deletedOwnTenPoints.length>0){
            let tmpPointList = [];
            let len = this.data.ownTenDivPointList.length;
            for (let index = 0; index < len; index++) {
                const element = this.data.ownTenDivPointList[index];
                let e = true;
                for(let k=0;k<this.deletedOwnTenPoints.length;k++){
                    if(element.id===parseInt(this.deletedOwnTenPoints[k])){
                        e = false;
                        break;
                    }
                }
                console.log(""+index,e);
                if(e){
                    tmpPointList.push(element);
                }
            }
            if(len != tmpPointList.length){
                this.dataChange = true;
            } 
            this.data.deletedOwnTenPoints = tmpPointList;
        }
    },
    addSelected(){
        let tmpPointList = [];
        let tmpPointList2 = [];
        for (let index = 0; index <  this.data.pointList.length; index++) {
            const element = this.data.pointList[index];
            let e = false;
            for(let k=0;k<this.selectedPoints.length;k++){
                if(element.id===parseInt(this.selectedPoints[k])){
                    e = true;
                break;
            }
            }
            if(e){
                tmpPointList.push(element);
            }
        }
       
        if(this.data.ownTenDiv === '1'){
            tmpPointList2 = this.data.allUsePointList;
            console.log('allUsePointList.length',this.data.allUsePointList.length)
            for(let k=0;k<tmpPointList.length;k++){
                const element = tmpPointList[k];
                let e = true;

                if(this.data.allUsePointList.length>0){
                    for (let index = 0; index <  this.data.allUsePointList.length; index++) {
                        if(element.id===this.data.allUsePointList[index].id){
                            e = false;
                            break;
                        }
                    }
                }
                if(e){
                    this.dataChange = true;
                    tmpPointList2.push(element);
                }
            }
            for(let k=0;k<tmpPointList2.length;k++){
                tmpPointList2[k].no=k+1;
            }
            this.data.allUsePointList = tmpPointList2;
        }else{
            tmpPointList2 = this.data.ownTenDivPointList;
            for(let k=0;k<tmpPointList.length;k++){
                const element = tmpPointList[k];
                let e = true;
                if(this.data.ownTenDivPointList.length>0){
                     for (let index = 0; index <  this.data.ownTenDivPointList.length; index++) {
                        if(element.id===this.data.ownTenDivPointList[index].id){
                            e = false;
                            break;
                        }
                    }
                }
                if(e){
                    this.dataChange = true;
                    tmpPointList2.push(element);
                }
            }
            for(let k=0;k<tmpPointList2.length;k++){
                tmpPointList2[k].no=k+1;
            }
            this.data.ownTenDivPointList = tmpPointList2;
        }
    },
    handleEneChange(act){
        console.log("handleEneChange",act);
        if(act===2){
            let year = this.data.measurementObject==='9999'?0:this.data.myyear;
            let un = this.data.unit===''?0:this.data.unit;
            let params =  {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId:   this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                selTenantId:this.data.selTenantId,
                statisticalMeasure:this.data.measurementObject,
                year:year,
                eneType: this.data.eneType,
                unit: un,
            };
            console.log("Change Params",params);
            this.loadData(params,this.initHandler);
        }else if(act===1){
            console.log("handleEneChange VALUE",this.data.eneType);
            let code = this.data.eneType;
            this.data.unit = '0';
            this.data.unitList = this.data.eneTypeUnitMap.get(code);
            this.data.pointList = [];
        }      
    },
    initPage(){

        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
        };
        console.log("InitPage Params",params);
        this.loadData(params,this.initHandler);
    },
    initHandler(action,result){
        if(action==0){
            console.log("InitPage Data",result);
            this.data.measurementObjectList = result.measurementObjectList;
            this.data.dateList= result.yearList;
            this.data.tenantList= result.tenantList;
            this.data.myyear = result.year;
            console.log("InitPage year", this.data.myyear);
        } else if(action==1){
            this.addSelPoint=false;
            this.delSelPoint=false;
            console.log("Search Data",result);

            let tmpPointList = [];
            for (let index = 0; index < result.allUsePointList.length; index++) {
                const element = result.allUsePointList[index];
                element.key=''+element.id;
                element.no=index+1;
                tmpPointList.push(element);
            }
            this.data.allUsePointList =tmpPointList; 
            let tmpPointList2 = [];
            for (let index = 0; index < result.ownTenDivPointList.length; index++) {
                const element = result.ownTenDivPointList[index];
                element.key=''+element.id;
                element.no=index+1;
                tmpPointList2.push(element);
            }
            this.data.ownTenDivPointList = tmpPointList2;
           
            this.data.ownTenDiv = result.ownTenDiv+"";
            this.data.ownTenDivList = result.ownTenDivList;
            this.data.bolOwnTenDiv = result.bolOwnTenDiv;

            this.data.eneTypeWithUnitList = result.eneTypeWithUnitList;
            if(this.data.eneTypeUnitMap==null)
                this.data.eneTypeUnitMap = new Map();
            this.data.eneTypeWithUnitList.forEach(element => {
                this.data.eneTypeUnitMap.set(element.no, element.children);
            });
            console.log("eneTypeUnitMap",this.data.eneTypeUnitMap);

            this.data.eneType = result.eneType+"";
            this.data.eneTypeList = result.eneTypeList;

            this.data.unitList =  this.data.eneTypeUnitMap.get(this.data.eneType);
            this.data.unit = result.unit+"";

            let tmpPointList3 = [];
            for (let index = 0; index < result.pointList.length; index++) {
                const element = result.pointList[index];
                element.key=''+element.id;
                element.no=index+1;
                tmpPointList3.push(element);
            }
            this.data.pointList = tmpPointList3;

        }else if(action==2){
            // this.data.unit = result.unit+"";
            // this.data.unitList = result.unitList;
            let tmpPointList = [];
            for (let index = 0; index < result.pointList.length; index++) {
                const element = result.pointList[index];
                element.key=''+element.id;
                element.no=index+1;
                tmpPointList.push(element);
            }
            this.data.pointList = tmpPointList;
            // this.addSelPoint = true;
        }else if(action==3){
            this.dataChange = false;
            this.searchEneMeasureObjectData(); 
        }
    },
    searchEneMeasureObjectData(){
        if(this.data.measurementObject==''){
            this.$message.error(this.$t('energy.enedata332'));//'请选择计量对象');
            return;
        }
        let year = this.data.measurementObject==='9999'?0:this.data.myyear;
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId:   this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            selTenantId:this.data.selTenantId,
            statisticalMeasure:this.data.measurementObject,
            year:year,
        };
        console.log("Search Params",params);
        if(this.dataChange){
            this.$confirm({
                title: this.$t('energy.enedata238'),//'确认要放弃所编辑的能源类型计量对象信息吗？',
                centered: true,
                onOk: () => {
                    this.loadData(params,this.initHandler);
                },
            });
        }else{
            this.loadData(params,this.initHandler);
        }
     
    },
    saveData(){
        let year = this.data.measurementObject==='9999'?0:this.data.myyear;
        let params =  {
            action:3,
            clientId: this.$store.getters.clientId,
            siteId:   this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            selTenantId:this.data.selTenantId,
            statisticalMeasure:this.data.measurementObject,  
            year:year,
            choiceAllUsePointList:this.data.allUsePointList,
            choiceOwnTenDivPointList:this.data.ownTenDivPointList,
        };
        console.log("SaveData Params",params);
        this.$confirm({
            title: this.$t('energy.enedata239'),//'确认要保存所设置的计量对象区分信息吗？',
            centered: true,
            onOk: () => {
                this.loadData(params,this.initHandler);
            },
        });
    },
    loadData(params,func){
     
        this.loading = true;
        getEneMeasureObjectSetup(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                func(params.action,res.data);
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    }
  },
  components: {
    'ene-header':EneHeader,
    'ene-point-table':EnePointTable,
  },
};
</script>

<style scoped>
#eneMeasureObject {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}

.display-between {
  display: flex;
  justify-content: space-between;
}
.display-between-end{
    align-items: flex-end;
}
.display-center{
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
   align-items: flex-start;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.condition {
  white-space: nowrap;
  overflow: hidden;
}
.condition-two {
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  margin: 12px 0px 8px 0px;
}

.button{
  padding: 8px 0;
}
.button button{
  width: 120px;
  margin: 0 12px;
}
</style>