<template>
  <div class="display-between">
    <div class="display-between" style="margin-right: 5px;">
      <!-- 建筑/租户 -->
        <span>{{$t('energy.enedata241')}} </span>
        <a-select default-value="0" style="width: 200px" @change="handleChange">
            <a-select-option
              v-for="(item, index) in tenantList"
              :key="index"
              :value="item.no"
            >
              {{ item.text }}
            </a-select-option>
        </a-select>
    </div>
    <div class="display-between" style="margin-right: 5px;">
      <!-- 能源类型 -->
        <span>{{$t('energy.enedata060')}}</span>
        <a-select default-value="" style="width: 200px" :disabled="eneKindDisabled" @change="handleChange2">
            <a-select-option
              v-for="(item, index) in eneKinds"
              :key="index"
              :value="item.no"
            >
              {{ item.text }}
            </a-select-option>
        </a-select>
    </div>
    <div class="display-between" style="margin-right: 5px;">
      <!-- 目标年度 -->
        <span>{{$t('energy.enedata005')}}</span>
        <a-select v-model="selYear" style="width: 120px" :disabled="yearDisabled" @change="handleChange3">
            <a-select-option
              v-for="(item, index) in yearList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
        </a-select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EneHeader',
  props: {
    tenantList: {
      type: Array,
      default: [],
    },
    eneKinds: {
      type: Array,
      default: [],
    },
    yearList: {
      type: Array,
      default: [],
    },
    year:{
      type: Number,
      default: 2020,
    }
  }, 
  data(){
    return {
      eneKindDisabled:false,
      yearDisabled:false,
      selYear:this.year,
    };
  },
  watch: {
    "$parent.data.year":{
      handler(val){
        console.log(val);
        if(val!==""){
          this.selYear = this.$parent.data.year;
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods: {
    handleChange(e) {
      this.$parent.data.selTenantId = e;
      console.log("handleChange",this.$parent.data.selTenantId);
    },
    handleChange2(e) {
      // if('9999'===e){
      //   this.yearDisabled = true;
      // }else{
      //   this.yearDisabled = false;
      // }
      this.$parent.data.measurementObject = e;
       console.log("handleChange2",this.$parent.data.measurementObject);
    },
    handleChange3(e) {
     
      this.$parent.data.year = e;
      console.log("handleChange3",this.$parent.data.year);
    },
  },
   components: {
  },
};
</script>

<style scoped>
    
    .display-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .display-between  span {
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
</style>